import {CreateControllerFn} from '@wix/yoshi-flow-editor'

import {isTemplateView} from '../../utils/flowAPI'

import {initReservationsStorage, mockReservationsStorage} from './storage'

const createController: CreateControllerFn = async ({flowAPI, controllerConfig}) => {
  return {
    async pageReady() {
      const isEditor = flowAPI.environment.isEditor
      const isPreview = flowAPI.environment.isPreview
      const isTemplate = isTemplateView(flowAPI)

      const shouldMockStorage = isEditor || isPreview || isTemplate

      const storage = shouldMockStorage
        ? mockReservationsStorage(flowAPI, controllerConfig)
        : initReservationsStorage(flowAPI, controllerConfig)

      flowAPI.controllerConfig.setProps(storage)

      await storage.getReservationLocations()
    },
  }
}

export default createController
